import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
	Row,
	Col,
	Card,
	Button,
	Avatar,
	Input,
	Form,
	InputNumber,
	Descriptions,
} from 'antd';
import axios from 'axios';
import { server } from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import profilavatar from '../assets/images/LogoCoalcar.jpeg';
import LogoIDT from '../assets/images/idtolu.png';
import LogoEmtiot from '../assets/images/Emtiot.png';
import LogoCoalcar from '../assets/images/LogoCoalcarN.png';

function Profile() {
	const { keycloak, initialized } = useKeycloak();
	const [userData, setUserData] = useState({
		idClient: `${keycloak.tokenParsed.sub}`,
		userName: `${keycloak.tokenParsed.name}`,
		userDescription: 'without description',
		userPhone: 'without Phone',
		userEmail: 'without Email',
	});
	let profileImage;
	if (keycloak.hasResourceRole('super-admin')) {
		profileImage = LogoIDT;
	} else if (keycloak.hasResourceRole('admin')) {
		profileImage = LogoCoalcar;
	} else if (keycloak.hasResourceRole('viewer')) {
		profileImage = LogoCoalcar;
	} else if (keycloak.hasResourceRole('demo')) {
		profileImage = LogoEmtiot;
	}
	const onFinish = async (values) => {
		const msToast = toast.loading('Loading...');
		toast.update(msToast, {
			render: 'Loading',
			type: 'info',
			closeOnClick: true,
			autoClose: true,
			isLoading: false,
		});
		setUserData({
			idClient: `${keycloak.tokenParsed.sub}`,
			userName: `${keycloak.tokenParsed.name}`,
			userDescription: `${values.userDescription}`,
			userPhone: `${values.userPhone}`,
			userEmail: `${values.userEmail}`,
		});
		await axios
			.post(`${server()}/us/update`, userData, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				toast.update(msToast, {
					position: 'top-center',
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'colored',
					render: 'Updated User Information',
					type: 'success',
					isLoading: false,
				});
			})
			.catch((er) => {
				toast.update(msToast, {
					position: 'top-center',
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'colored',
					render: 'Ups... Changes not save',
					type: 'error',
					isLoading: false,
				});
			});
	};

	// async function GetUserData() {
	// 	await axios
	// 		.get(`${server()}/us/user/?id=${keycloak.tokenParsed.sub}`, {
	// 			headers: { 'Content-Type': 'application/json' },
	// 			withCredentials: false,
	// 		})
	// 		.then((res) => {
	// 			const data = res.data[0];
	// 			// console.log(data);
	// 			setUserData({
	// 				idClient: `${data.idClient}`,
	// 				userName: `${data.userName}`,
	// 				userDescription: `${data.userDescription}`,
	// 				userPhone: `${data.userPhone}`,
	// 				userEmail: `${data.userEmail}`,
	// 			});
	// 		})
	// 		.catch((e) => {
	// 			window.alert(e);
	// 		});
	// }
	useEffect(() => {
		// GetUserData();
	}, []);

	return (
		<>
			<div className='layout-content'>
				<ToastContainer
					position='top-center'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
				<Row>
					<Col span={24} className='mb-24'>
						<Card
							className='layout-content'
							bodyStyle={{ display: 'none' }}
							title={
								<Row justify='space-between' align='middle' gutter={[24, 0]}>
									<Col span={24} md={12} className='col-info'>
										<Avatar.Group>
											<Avatar size={80} shape='square' src={profileImage} />

											<div className='avatar-info'>
												<h4 className='font-semibold m-0'>
													{keycloak.tokenParsed.given_name}
												</h4>
												<p>{keycloak.tokenParsed.email}</p>
											</div>
										</Avatar.Group>
									</Col>
								</Row>
							}
						></Card>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} md={24} className='mb-24'>
						<Form onFinish={onFinish}>
							<Row>
								<Col span={12}>
									<Card bordered={false} className='header-solid h-full'>
										<ul className='list settings-list'>
											<li>
												{/* <Form.Item name='userName' label='Name'>
													<Input
														placeholder={keycloak.tokenParsed.name}
														disabled
													/>
												</Form.Item> */}
												<Descriptions
													title={
														<h3 className='font-semibold m-0'>
															Profile Information
														</h3>
													}
												>
													<Descriptions.Item label='Name' span={3}>
														{keycloak.tokenParsed.given_name}
													</Descriptions.Item>
													<Descriptions.Item label='Last Name' span={3}>
														{keycloak.tokenParsed.family_name}
													</Descriptions.Item>
													<Descriptions.Item label='Email' span={3}>
														{keycloak.tokenParsed.email}
													</Descriptions.Item>
												</Descriptions>
											</li>

											{/* <Form.Item name='userDescription' label='Description'>
													<Input.TextArea
														maxLength={200}
														placeholder={userData.userDescription}
													/>
												</Form.Item>
												<Form.Item name='userPhone' label='Number Phone'>
													<Input placeholder={userData.userPhone} />
												</Form.Item>
												<Form.Item name='userEmail' label='Email'>
													<Input placeholder={userData.userEmail}></Input>
												</Form.Item> */}
										</ul>
										{/* <Form.Item>
											<Button type='primary' htmlType='submit'>
												Save Changes
											</Button>
										</Form.Item> */}
									</Card>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</div>

			{/* <Card
				bordered={false}
				className='header-solid mb-24'
				title={
					<>
						<h6 className='font-semibold'>Projects</h6>
						<p>Architects design houses</p>
					</>
				}
			>
				<Row gutter={[24, 24]}>
					{project.map((p, index) => (
						<Col span={24} md={12} xl={6} key={index}>
							<Card
								bordered={false}
								className='card-project'
								cover={<img alt='example' src={p.img} />}
							>
								<div className='card-tag'>{p.titlesub}</div>
								<h5>{p.titile}</h5>
								<p>{p.disciption}</p>
								<Row gutter={[6, 0]} className='card-footer'>
									<Col span={12}>
										<Button type='button'>VIEW PROJECT</Button>
									</Col>
									<Col span={12} className='text-right'>
										<Avatar.Group className='avatar-chips'>
											<Avatar size='small' src={profilavatar} />
											<Avatar size='small' src={convesionImg} />
											<Avatar size='small' src={convesionImg2} />
											<Avatar size='small' src={convesionImg3} />
										</Avatar.Group>
									</Col>
								</Row>
							</Card>
						</Col>
					))}
					<Col span={24} md={12} xl={6}>
						<Upload
							name='avatar'
							listType='picture-card'
							className='avatar-uploader projects-uploader'
							showUploadList={false}
							action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
							beforeUpload={beforeUpload}
							onChange={handleChange}
						>
							{imageURL ? (
								<img src={imageURL} alt='avatar' style={{ width: '100%' }} />
							) : (
								uploadButton
							)}
						</Upload>
					</Col>
				</Row>
			</Card> */}
		</>
	);
}
export default Profile;
