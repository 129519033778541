import {
	Button,
	Row,
	Col,
	Card,
	Table,
	Typography,
	Select,
	Space,
	DatePicker,
} from 'antd';

import axios from 'axios';
import { server } from '../api/axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { GenerateExcel } from '../api/Reports/ReportExcel';
import { GenerateCsv } from '../api/Reports/ReportCsv';

// Images
const { Title } = Typography;
/**
 * Columnas de la Tabla
 */
const columnas = [
	{
		title: 'DATE',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'VARIABLES',
		children: [
			{
				title: 'Temperature',
				dataIndex: 'Temperature',
				key: 'Temperature',
				width: 100,
			},
			{
				title: 'Water Temperature',
				dataIndex: 'waterTemperature',
				key: 'waterTemperature',
				width: 100,
			},
			{
				title: 'PH',
				dataIndex: 'ph',
				key: 'ph',
				width: 100,
			},
			{
				title: 'Dissolved Oxygen',
				dataIndex: 'dissolvedOxygen',
				key: 'dissolvedOxygen',
				width: 100,
			},
		],
	},
	{
		title: 'DEVICE',
		dataIndex: 'device',
		key: 'device',
	},
	{
		title: 'LOCATION',
		dataIndex: 'location',
		key: 'location',
	},
];
// table code start
/**
 * Opciones Select
 */
const { Option } = Select;
const { RangePicker } = DatePicker;

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};
const Report = () => {
	/**
	 * Definición captura de la zona horaria del cliente
	 */
	const timeZone = moment.tz.guess();
	/**
	 * Hooks Filtros (Parametros de las consultas)
	 */
	const [parameter, setParameter] = useState({
		id: '4d976feb5e201fa0',
		date: {
			start: moment().clone().tz('America/Bogota').startOf('year').valueOf(),
			end: moment().clone().tz('America/Bogota').valueOf(),
		},
	});
	const [dataReport, setDataReport] = useState({});
	/**
	 * Hook control toast
	 */
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	/**
	 * Handle Para la captura de los eventos de los Select
	 * @param {*} tanque
	 */
	const handleChangeTanque = (tanque) => {
		/**
		 * Se captura el Valor del Campo
		 */
		parameter.id = tanque;
		setParameter(parameter);
	};

	const handleChangeDate = (date) => {
		const dates = {};
		/**
		 * se capturan y se convierten las fechas a la zona horaria del servidor y a milisegundos
		 */
		dates.start = moment(date[0]).clone().tz('America/Bogota').valueOf();
		dates.end = moment(date[1]).clone().tz('America/Bogota').valueOf();
		// parameter.date = dates;
		setParameter(parameter);
	};
	const getTest = async (parameter) => {
		const id = toast.loading('Loading...');
		toast.update(id, {
			render: 'Loading',
			type: 'info',
			closeOnClick: true,
			autoClose: true,
			isLoading: false,
		});
		await axios
			.post(`${server()}/mg/report`, parameter, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				let array = [];
				setIsLoading(false);
				array = response.data;
				setData(array);
				setDataReport(array);

				toast.update(id, {
					render: 'Load successfuly',
					type: 'success',
					closeOnClick: true,
					autoClose: true,
					isLoading: false,
				});
			})
			.catch((e) => {
				toast.update(id, {
					render: `Error: ${e.response.statusText}`,
					type: 'error',
					closeOnClick: true,
					isLoading: false,
				});
				console.log(e);
			});
	};
	/**
	 * Control Boton Submit
	 * @param {*} event
	 */
	const handleSubmit = (event) => {
		getTest(parameter);
	};

	/**
	 * Hook control get data
	 */
	useEffect(() => {
		console.log(parameter);
		getTest(parameter).then((res) => {
			//console.log(res);
		});
	}, []);

	/**
	 * Variable para almacenar la data con la que se genera el archivo CSV
	 */
	const dataToCsv = data;
	return (
		<>
			<div className='tabled'>
				<ToastContainer />
				<Row>
					<Col xs='24' xl={24}>
						<Card
							bordered={true}
							className='criclebox tablespace mb-24'
							title='Measurements'
						>
							<div>
								<Row>
									<Card bordered={true} className='criclebox tablespace mb-24 '>
										<Col>
											<div className='site-space-compact-wrapper'>
												{/* <Input style={{with:'50%'}}  name="Time" label="Time" type="number" placeholder="Tiempo en Minutos"  id="time" onChange={onHandleParams} disabled={isLoading}/> */}
												<Space.Compact block size='large'>
													<RangePicker
														onChange={handleChangeDate}
														style={{ width: '70%' }}
													/>
													<Select
														placeholder='Choose The Tank'
														name='tanque'
														onChange={handleChangeTanque}
													>
														<Option value='4d976feb5e201fa0'>Tank 1</Option>
													</Select>
													{/* <Select
														placeholder='Variable'
														name='variable'
														onChange={handleChangeVariable}
													>
														<Option value='Temperature'>Temperature</Option>
														<Option value='Water_Temperature'>
															Water Temperature
														</Option>
														<Option value='Ph'>Ph</Option>
														<Option value='Disolved_Oxigen'>
															Disolved Oxigen
														</Option>
													</Select> */}
													<Button type='primary' onClick={handleSubmit}>
														Submit
													</Button>
												</Space.Compact>
											</div>
										</Col>
									</Card>
									<Col span={12}>
										<Space>
											<GenerateExcel dataSet={dataReport} />
											<GenerateCsv dataSet={dataToCsv} fileName='Report' />
											{/* <Button type='primary' icon={<DownloadOutlined />}>
												Download PDF File
											</Button> */}
										</Space>
									</Col>
								</Row>
							</div>
							<div className='table-responsive'>
								<Table
									columns={columnas}
									dataSource={data}
									pagination={true}
									className='ant-border-space'
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default Report;
