import { Switch, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import Report from '../pages/Report';
import Notifications from '../pages/Notifications';
import Profile from '../pages/Profile';
import Main from '../components/layout/Main';
import ConfigurateTank from '../pages/Tank';
import PrivateRoute from '../api/service/helper/private-route';
import Blockchain from '../pages/Block';
import { Spin, Layout } from 'antd';
const { Content } = Layout;
const contentStyle = {
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	minHeight: '800px',
	alignItems: 'center',
	lineHeight: '120px',
	color: '#fff',
};

const AuthRoutes = () => {
	const { keycloak, initialized } = useKeycloak();
	if (!initialized) {
		return (
			<Layout>
				<Content style={contentStyle}>
					<Spin tip='Loading' size='large' />
				</Content>
			</Layout>
		);
	}

	if (
		keycloak.hasResourceRole('admin') ||
		keycloak.hasResourceRole('demo') ||
		keycloak.hasResourceRole('super-admin')
	) {
		return (
			<PrivateRoute>
				<Switch>
					<Main>
						<Route exact path='/' component={Home} />
						<Route exact path='/report' component={Report} />
						<Route exact path='/notifications' component={Notifications} />
						<Route exact path='/profile' component={Profile} />
						<Route exact path='/tanks' component={ConfigurateTank} />
						<Route exact path='/blockchain' component={Blockchain} />
					</Main>
				</Switch>
			</PrivateRoute>
		);
	} else if (keycloak.hasResourceRole('customer')) {
		return (
			<Switch>
				<Main>
					<Route exact path='/' component={Home} />
					<Route exact path='/report' component={Report} />
					<Route exact path='/notifications' component={Notifications} />
					<Route exact path='/profile' component={Profile} />
					<Route exact path='/tanks' component={ConfigurateTank} />
					<Route exact path='/blockchain' component={Blockchain} />
				</Main>
			</Switch>
		);
	}

	return (
		<Route path='/' component={SignIn} />
		// <Route path='/' render={() => keycloak.login()} />
	);
};
export default AuthRoutes;
