import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';
import { Button } from 'antd';
import { FaFileExcel } from 'react-icons/fa';
/**
 *
 * @param {*} dataSet
 * @param {*} fileName
 * @returns component for generate Excel file
 */
export const GenerateExcel = (dataSet, fileName) => {
	/**
	 * file type is define
	 */
	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	/**
	 * funtion for generate file excel
	 */
	const exportToExcel = async () => {
		// console.log(fileName);
		/**
		 * the Json is converted to an excel-sheet
		 */
		const ws = XLSX.utils.json_to_sheet(dataSet.dataSet);
		/**
		 * the book is created
		 */
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		/**
		 * Attempts to write the workbook data
		 */
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		/**
		 * expands to support files on the user's system.
		 */
		const data = new Blob([excelBuffer], { type: fileType });
		/**
		 * the file is save
		 */
		FileSaver.saveAs(data, fileName);
	};
	return (
		<>
			<Button
				type='primary'
				onClick={(e) => exportToExcel((fileName = 'Reporte_'))}
				icon={<FaFileExcel />}
			>
				Download Excel File
			</Button>
		</>
	);
};
