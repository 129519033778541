/* eslint-disable react/prop-types */
import { Avatar, Menu } from 'antd';
import LogoCoalcar from '../../assets/images/LogoCoalcarN.png';
import LogoEmtiot from '../../assets/images/Emtiot.png';
import LogoIDT from '../../assets/images/idtolu.png';
import { useKeycloak } from '@react-keycloak/web';
/**
 * Iconos
 */
import { NavLink, useLocation, Link } from 'react-router-dom';
import {
	FaRegBell,
	FaRegChartBar,
	FaTh,
	FaUserEdit,
	FaCogs,
	FaCubes,
} from 'react-icons/fa';
/**
 *
 * @param {*} label
 * @param {*} key
 * @param {*} icon
 * @param {*} children
 * @returns
 */
function getItem(key, children, label) {
	return {
		key,
		children,
		label,
	};
}
function Sidenav(props) {
	const { keycloak, initialized } = useKeycloak();
	const { pathname } = useLocation();
	const page = pathname.replace('/', '');
	let items;
	let brand;

	function GenerateLinkItem(id, path, icon, label) {
		return getItem(
			id,
			null,
			<Link to={path} className='nav-link'>
				<span
					className='icon'
					style={{ background: page === path.substring(1) ? props.color : '' }}
				>
					{icon}
				</span>
				<span className='label'>{label}</span>
			</Link>
		);
	}

	const roleConfig = {
		'super-admin': [
			{
				path: '/',
				icon: <FaTh />,
				label: 'Dashboard',
			},
			{
				path: '/report',
				icon: <FaRegChartBar />,
				label: 'Report',
			},
			{
				path: '/notifications',
				icon: <FaRegBell />,
				label: 'Notifications',
			},
			{
				path: '/tanks',
				icon: <FaCogs />,
				label: 'Tank Configuration',
			},
			{
				path: '/blockchain',
				icon: <FaCubes />,
				label: 'Blockchain',
			},
			{
				path: '/profile',
				icon: <FaUserEdit />,
				label: 'Profile',
			},
		],
		admin: [
			{
				path: '/',
				icon: <FaTh />,
				label: 'Dashboard',
			},
			{
				path: '/report',
				icon: <FaRegChartBar />,
				label: 'Report',
			},
			{
				path: '/notifications',
				icon: <FaRegBell />,
				label: 'Notifications',
			},
			{
				path: '/tanks',
				icon: <FaCogs />,
				label: 'Tank Configuration',
			},
			{
				path: '/blockchain',
				icon: <FaCubes />,
				label: 'Blockchain',
			},
			{
				path: '/profile',
				icon: <FaUserEdit />,
				label: 'Profile',
			},
		],
		customer: [
			{
				path: '/',
				icon: <FaTh />,
				label: 'Dashboard',
			},
			{
				path: '/report',
				icon: <FaRegChartBar />,
				label: 'Report',
			},
			{
				path: '/notifications',
				icon: <FaRegBell />,
				label: 'Notifications',
			},
			{
				path: '/tanks',
				icon: <FaCogs />,
				label: 'Tank Configuration',
			},
			{
				path: '/blockchain',
				icon: <FaCubes />,
				label: 'Blockchain',
			},
			{
				path: '/profile',
				icon: <FaUserEdit />,
				label: 'Profile',
			},
		],
		demo: [
			{
				path: '/',
				icon: <FaTh />,
				label: 'Dashboard',
			},
			{
				path: '/report',
				icon: <FaRegChartBar />,
				label: 'Report',
			},
			{
				path: '/notifications',
				icon: <FaRegBell />,
				label: 'Notifications',
			},
			{
				path: '/tanks',
				icon: <FaCogs />,
				label: 'Tank Configuration',
			},
			{
				path: '/blockchain',
				icon: <FaCubes />,
				label: 'Blockchain',
			},
			{
				path: '/profile',
				icon: <FaUserEdit />,
				label: 'Profile',
			},
		],
	};

	let currentRole = null;
	if (keycloak.hasResourceRole('super-admin')) {
		currentRole = 'super-admin';
		brand = LogoIDT;
	} else if (keycloak.hasResourceRole('admin')) {
		currentRole = 'admin';
		brand = LogoCoalcar;
	} else if (keycloak.hasResourceRole('viewer')) {
		currentRole = 'viewer';
		brand = LogoCoalcar;
	} else if (keycloak.hasResourceRole('demo')) {
		currentRole = 'demo';
		brand = LogoEmtiot;
	}

	if (currentRole && roleConfig[currentRole]) {
		items = roleConfig[currentRole].map((item, index) =>
			GenerateLinkItem((index + 1).toString(), item.path, item.icon, item.label)
		);
	}

	return (
		<>
			<div className='brand'>
				<img src={brand} width='170px' height='250px' alt='' />
			</div>
			<div>
				<Menu mode='inline' theme='light' items={items} />
			</div>
		</>
	);
}
export default Sidenav;
