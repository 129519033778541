import { useState } from 'react';
import axios from 'axios';
import { Layout, Typography, Button } from 'antd';

import { server } from '../api/axios';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect, Route } from 'react-router-dom';
const { Title } = Typography;
const { Content } = Layout;
/**
 *
 * @returns
 */
export default function SignIn() {
	const { keycloak, initialized } = useKeycloak();
	// const { setAuth } = useContext(AuthContext);
	const [data, setData] = useState({ username: '', password: '' });
	/**
	 *
	 * @param {*} req
	 * @param {*} res
	 * @param {*} next
	 * @returns
	 */

	// se crea el evento para el botón submit
	const handleSubmit = async (event) => {
		// se evitan los los valores por defectos
		event.preventDefault();
		// promesa permite la conexión con el endpoint de servidor
		// se le especifica el método + instancia de la url y se le pasan los datos de los input
		await axios
			.post(`${server()}/auth`, data, {
				// se especifican el header de la petición
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				withCredentials: false,
			})
			.then((response) => {
				console.log(response.data.code);
			})
			.catch((error) => {
				window.alert('Ups,Tus Credenciales estan Erradas');
				console.log(error); // se imprime el error
			});
	};
	/**
	 * Se capturan los datos del Usuario
	 * @param {*} event
	 */
	const handleChange = (event) => {
		const fields = { username: data.username, password: data.password }; // se seteaan los valores de los input al objeto
		fields[event.target.name] = event.target.value;
		setData(fields); // se pasan los datos del objeto al estado
	};

	return (
		<>
			<div className='layout-default ant-layout layout-sign-up'>
				<Content className='p-0'>
					<div className='sign-up-header'>
						<div className='content'>
							<Title>Go to Single Sign-On</Title>
							<Button type='primary'>
								<a id='submit' type='primary' onClick={() => keycloak.login()}>
									Go
								</a>
							</Button>
						</div>
					</div>
				</Content>
			</div>
			{/* <Content className='signin'>
				<Row justify='center'>
					<Col xs={24} lg={24} md={24} xl={24}>
						<Title className='mb-15'>Go to Single Sign-On</Title>
					</Col>

					<Col
						className='sign-img'
						xs={{ span: 24 }}
						lg={{ span: 24 }}
						md={{ span: 24 }}
					>
						<img src={signinbg} alt='' />
					</Col>
					<Col>
						<div>
							<Button type='primary'>
								<a id='submit' type='primary' onClick={() => keycloak.login()}>
									Go
								</a>
							</Button>
						</div>
					</Col>
				</Row>
			</Content> */}
		</>
	);
}
