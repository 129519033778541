import { Layout, Row, Col } from 'antd';
import LogoIDT from '../../assets/images/idtolu.png';
import LogoEmtiot from '../../assets/images/Emtiot.png';
import LogoCoalcar from '../../assets/images/LogoCoalcarN.png';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';

function Footer() {
	const { Footer: AntFooter } = Layout;
	const { keycloak, initialized } = useKeycloak();
	let brand;
	if (keycloak.hasResourceRole('super-admin')) {
		brand = LogoEmtiot;
	} else if (keycloak.hasResourceRole('admin')) {
		brand = LogoCoalcar;
	} else if (keycloak.hasResourceRole('viewer')) {
		brand = LogoCoalcar;
	} else if (keycloak.hasResourceRole('demo')) {
		brand = LogoEmtiot;
	}
	return (
		<AntFooter style={{ background: '#fafafa' }}>
			<Row>
				<Col xs={24} md={24} lg={12}>
					<div>
						<img src={brand} alt='Logo_SIC' width='80px' height='32px' />{' '}
						<a
							href='http://idtolu.com/'
							className='font-weight-bold'
							target='_blank'
							rel='noreferrer'
						>
							Powered By
						</a>{' '}
						<img src={LogoIDT} alt='Logo_IDT' width='80px' height='32px' />
					</div>
				</Col>
				<Col xs={24} md={12} lg={12}>
					<div className='footer-menu'>
						<ul>
							<li className='nav-item'>
								<a
									href='http://idtolu.com/nosotros'
									className='nav-link text-muted'
									target='_blank'
									rel='noreferrer'
								>
									About Us
								</a>
							</li>
							<li className='nav-item'>
								<a
									href='http://idtolu.com/contacto'
									className='nav-link text-muted'
									target='_blank'
									rel='noreferrer'
								>
									Contact us
								</a>
							</li>
						</ul>
					</div>
				</Col>
			</Row>{' '}
			<Row>
				<Col
					xs={24}
					md={24}
					lg={24}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<div className='copyright'>
						© {moment().year()}
						<a
							href='http://idtolu.com/'
							className='font-weight-bold'
							target='_blank'
							rel='noreferrer'
						>
							ID TOLU
						</a>
						All rights reserved
					</div>
				</Col>
			</Row>
		</AntFooter>
	);
}

export default Footer;
