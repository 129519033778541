import { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	Collapse,
	Avatar,
	Select,
	Form,
	Table,
	message,
	Button,
} from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import Settings from '../assets/images/settings.png';
import axios from 'axios';
import { server } from '../api/axios';
const { Panel } = Collapse;
const { Option } = Select;
const text = `
Configure the ranges in which alerts are issued for each tank.
`;
const columnas = [
	{
		title: 'ACTIONS',
		dataIndex: 'action',
		key: 'action',
		width: 100,
	},

	{
		title: 'PARAMETERS',
		children: [
			{
				title: 'Temperature',
				children: [
					{
						title: 'Max',
						dataIndex: 'temperature',
						key: 'temperature',
						width: 25,
						render: (temperature) => temperature.max,
					},
					{
						title: 'Min',
						dataIndex: 'temperature',
						key: 'temperature',
						width: 25,
						render: (temperature) => temperature.min,
					},
				],
			},
			{
				title: 'Water Temperature',

				children: [
					{
						title: 'Max',
						dataIndex: 'WaterTemperature',
						key: 'WaterTemperature',
						width: 25,
						render: (WaterTemperature) => WaterTemperature.max,
					},
					{
						title: 'Min',
						dataIndex: 'WaterTemperature',
						key: 'WaterTemperature',
						width: 25,
						render: (WaterTemperature) => WaterTemperature.min,
					},
				],
			},
			{
				title: 'PH',

				children: [
					{
						title: 'Max',
						dataIndex: 'ph',
						key: 'ph',
						width: 25,
						render: (ph) => ph.max,
					},
					{
						title: 'Min',
						dataIndex: 'ph',
						key: 'ph',
						width: 25,
						render: (ph) => ph.min,
					},
				],
			},
			{
				title: 'Dissolved Oxygen',

				children: [
					{
						title: 'Max',
						dataIndex: 'dissolvedOxygen',
						key: 'dissolvedOxygen',
						width: 25,
						render: (dissolvedOxygen) => dissolvedOxygen.max,
					},
					{
						title: 'Min',
						dataIndex: 'dissolvedOxygen',
						key: 'dissolvedOxygen',
						width: 25,
						render: (dissolvedOxygen) => dissolvedOxygen.min,
					},
				],
			},
		],
	},
	{
		title: 'DEVICE',
		dataIndex: 'device',
		key: 'device',
	},
	{
		title: 'DATE',
		dataIndex: 'date',
		key: 'date',
		width: 50,
	},
	{
		title: 'LOCATION',
		dataIndex: 'location',
		key: 'location',
	},
];

function ConfigurateTank() {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const getTest = async (parameter) => {
		const id = toast.loading('Loading...');
		toast.update(id, {
			render: 'Loading',
			type: 'info',
			closeOnClick: true,
			autoClose: true,
			isLoading: false,
			position: 'top-center',
		});
		await axios
			.get(`${server()}/mg/tank`, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				let array = [];
				setIsLoading(false);
				array = response.data;
				// res.data.forEach((element) => {
				// 	element.actions = (
				// 	  <>
				// 		<Button type="primary">
				// 		  <Link to={`/edit/${element.id}`}>Edit</Link>
				// 		</Button>
				// 		<Button
				// 		  onClick={() => {
				// 			axios
				// 			  .post("http://localhost:3333/mongo/delete", {
				// 				id: element.id,
				// 			  })
				// 			  .then((res) => {
				// 				//getData();
				// 			  })
				// 			  .catch((err) => {
				// 				alert(err);
				// 			  });
				// 		  }}
				// 		  type="primary"
				// 		  danger
				// 		>
				// 		  Delete
				// 		</Button>
				// 	  </>
				// 	);
				//   });
				setData(array);

				toast.update(id, {
					render: 'Data Load Successfuly',
					type: 'success',
					closeOnClick: true,
					autoClose: true,
					isLoading: false,
					position: 'top-center',
				});
			})
			.catch((e) => {
				toast.update(id, {
					render: `Error: ${e.response}`,
					type: 'error',
					closeOnClick: true,
					isLoading: false,
					position: 'top-center',
				});
				console.log(e);
			});
	};
	useEffect(() => {
		getTest();
	}, []);
	return (
		<>
			<div className='layout-content'>
				<ToastContainer />
				<Row>
					<Col span={24} className='mb-24'>
						<Card
							className='layout-content'
							bodyStyle={{ display: 'none' }}
							title={
								<Row justify='space-between' align='middle' gutter={[24, 0]}>
									<Col span={24} md={12} className='col-info'>
										<Avatar.Group>
											<Avatar size={74} shape='square' src={Settings} />
											<div className='avatar-info'>
												<h4 className='font-semibold m-0'>Tanks Settings</h4>
											</div>
										</Avatar.Group>
									</Col>
								</Row>
							}
						></Card>
					</Col>
				</Row>
				<Row>
					<Col xs={24} xl={24}>
						<Card
							bordered={true}
							className='criclebox tablespace mb-24'
							title='Setting'
						>
							<div className='table-responsive'>
								<Table
									columns={columnas}
									dataSource={data}
									pagination={true}
									className='ant-border-space'
								/>
							</div>
						</Card>
					</Col>
				</Row>

				{/** Lista de Configuracion */}
				{/* <Row gutter={[24, 0]}>
					<Col span={24} md={24} className='mb-24 '>
						<Card
							bordered={false}
							className='header-solid h-full'
							title={
								<h6 className='font-semibold m-0'>
									Parameters Ranges per Tank
								</h6>
							}
						>
							<Space direction='vertical'>
								<Form>
									<Collapse collapsible='header' defaultActiveKey={['1']}>
										<Panel header='Tank 1' key='1'>
											<p>{text}</p>
											<Space.Compact block size='large'>
												<Select
													defaultValue='Temperature'
													placeholder='Variable'
													name='variable'
												>
													<Option value='Temperature'>Temperature</Option>
													<Option value='Water_Temperature'>
														Water Temperature
													</Option>
													<Option value='Ph'>Ph</Option>
													<Option value='Disolved_Oxigen'>
														Disolved Oxigen
													</Option>
												</Select>
												<Input
													type='number'
													style={{
														textAlign: 'center',
													}}
													placeholder='Min.'
												/>
												<Input
													type='number'
													style={{
														textAlign: 'center',
													}}
													placeholder='Max.'
												/>

												<Button type='primary'> Update</Button>
											</Space.Compact>
										</Panel>
									</Collapse>
									<Collapse collapsible='icon'>
										<Panel header='Tank 2' key='1'>
											<p>{text}</p>
											<Space.Compact block size='large'>
												<Form.Item>
													<Select
														defaultValue='Temperature'
														placeholder='Variable'
														name='variable'
													>
														<Option value='Temperature'>Temperature</Option>
														<Option value='Water_Temperature'>
															Water Temperature
														</Option>
														<Option value='Ph'>Ph</Option>
														<Option value='Disolved_Oxigen'>
															Disolved Oxigen
														</Option>
													</Select>
												</Form.Item>
												<Form.Item>
													<Input
														type='number'
														style={{
															textAlign: 'center',
														}}
														placeholder='Min.'
													/>
												</Form.Item>
												<Form.Item>
													<Input
														type='number'
														style={{
															textAlign: 'center',
														}}
														placeholder='Max.'
													/>
												</Form.Item>

												<Button type='primary'> Update</Button>
											</Space.Compact>
										</Panel>
									</Collapse>
								</Form>
							</Space>
						</Card>
					</Col>
				</Row> */}
			</div>
		</>
	);
}
export default ConfigurateTank;
