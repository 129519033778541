/**
 * Libraries
 */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment-timezone';
import {
	Card,
	Col,
	Row,
	Typography,
	Select,
	DatePicker,
	Divider,
	Popover,
	Image,
} from 'antd';
/**
 * Icons
 */
import {
	WiBarometer,
	WiSolarEclipse,
	WiRaindrop,
	WiThermometer,
} from 'react-icons/wi';
/**
 * Components
 */
import { server } from '../api/axios';
import Charts from '../components/chart/ChartsLine';
import ScalePh from '../assets/images/scalesPh.jpeg';
import ScaleDo from '../assets/images/scaleDo.jpeg';
import ScaleTem from '../assets/images/scaleTemperature.jpeg';
/**
 * Constants
 */
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;
/**
 *
 * @returns Home
 */
function Home() {
	const { keycloak, inicialiced } = useKeycloak();
	axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;

	const timeZone = moment.tz.guess();
	const [param, setParam] = useState({
		id: '4d976feb5e201fa0',
		date: {
			start: moment().tz(timeZone).startOf('year').valueOf(),
			end: moment().tz(timeZone).valueOf(),
		},
	});
	const [data, setData] = useState([]);
	/**
	 * Hooks Para los ultimos datos de cada variables
	 */
	const [lastValues, setLastValues] = useState({
		name: 'Tank',
		measurement: {
			waterQuality: {
				Temperature: 0,
				ph: 0,
				dissolvedOxygen: 0,
				waterTemperature: 0,
			},
		},
		date: `${moment().tz(timeZone).format('YYYY-MM-DD HH:mm')}`,
	});
	const ReadLast = (id) => {
		axios
			.post(`${server(keycloak.token)}/mg/last`, id, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				/**
				 * se setean los valores
				 */
				setLastValues(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	/**
	 * Variables de los parametros
	 */

	/**
	 * Reloj Dashboard
	 */
	const [dataTime, setDataTime] = useState(
		`${moment().format('LL')} ${moment().format('LTS')}`
	);
	/**
	 * Cards Value Dashboard
	 */
	const cart = [
		{
			etiqueta: 'Climate Temperature',
			value: lastValues.measurement.waterQuality.Temperature,
			unit: 'ºC',
			icon: <WiSolarEclipse size={40} />,
			bnb: 'bnb2',
			time: `${lastValues.date}`,
			scale: (
				<>
					<div>
						<Title level={5}> Scale Climate Temperature</Title>
						<Image
							width={125}
							src={ScaleTem}
							alt='Scale Climate Temperature'
						></Image>
					</div>
				</>
			),
		},
		{
			etiqueta: 'pH',
			value: lastValues.measurement.waterQuality.ph,
			unit: '',
			icon: <WiBarometer size={40} />,
			bnb: 'bnb2',
			time: lastValues.date,
			scale: (
				<>
					<di>
						<Title level={5}> Scale pH</Title>
						<Image width={200} src={ScalePh} alt='Scale ph'></Image>
					</di>
				</>
			),
		},
		{
			etiqueta: 'Dissolved Oxygen',
			value: lastValues.measurement.waterQuality.dissolvedOxygen,
			unit: 'mg/L',
			icon: <WiRaindrop size={40} />,
			bnb: 'bnb2',
			time: `${lastValues.date}`,
			scale: (
				<>
					<di>
						<Title level={5}> Scale Dissolved Oxygen</Title>
						<Image
							width={200}
							src={ScaleDo}
							alt='Scale Dissolved Oxygen'
						></Image>
					</di>
				</>
			),
		},
		{
			etiqueta: 'Water Temperature',
			value: lastValues.measurement.waterQuality.waterTemperature,
			unit: 'ºC',
			icon: <WiThermometer size={40} />,
			bnb: 'bnb2',
			time: `${lastValues.date}`,
			scale: (
				<>
					<di>
						<Title level={5}> Scale Water Temperature </Title>
						<Image
							width={150}
							src={ScaleTem}
							alt='Scale Water Temperature'
						></Image>
					</di>
				</>
			),
		},
	];
	/**
	 *
	 * @param {*} params
	 */
	const getData = (param) => {
		param.tz = timeZone;
		axios
			.post(`${server(keycloak.token)}/mg/data`, param, {
				// se especifican el headers de la peticion
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false, // se desabilitan las politicas Cors
			})
			.then((response) => {
				/**
				 * se cetean la data de los graficos de lineas
				 */
				setData(response.data);
			})
			.catch((error) => {
				console.log(error); // se imprime el error
			});
	};
	/**
	 * Handle que captura el valor del Select Tank del panel de control
	 * @param {*} tank
	 */
	const handleChangeTank = (tank) => {
		param.id = tank;
		setParam(param);
		getData(param);
		//getLast();
		ReadLast({
			id: `${param.id}`,
			tz: `${timeZone}`,
		});
	};
	/**
	 * Handle que captura el valor del DatePiker del panel de control
	 * @param {*} date
	 */
	const handleChangeDate = (date) => {
		/**
		 * Se asigna un formato al valor de la fecha capturada
		 */
		const dates = {};
		dates.start = moment(date[0]).clone().tz('America/Bogota').valueOf();
		dates.end = moment(date[1]).clone().tz('America/Bogota').valueOf();
		if (date == null) {
			dates.start = moment()
				.clone()
				.tz(timeZone)
				.startOf('year')
				.format('YYYY-MM-DDTHH:mm:ss')
				.valueOf();
			dates.end = moment().clone().tz(timeZone).valueOf();
		}
		param.date = dates;
		setParam(param);
		getData(param);
	};
	useEffect(() => {
		const timer = setInterval(() => {
			const date = new Date();
			setDataTime(`${moment().format('LL')} ${moment().format('LTS')}`);
		}, 1000);
		getData(param);
		ReadLast({
			id: `${param.id}`,
			tz: `${timeZone}`,
		});
	}, []);
	return (
		<>
			<div className='layout-content'>
				<Row className='rowgap-vbox' gutter={[24, 0]}>
					{/**
					 * Panel Fecha
					 */}
					<Col xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
						<Card bordered={false} className='criclebox '>
							<div className='number'>
								<Row align='middle' gutter={[24, 0]}>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<RangePicker
											name='date'
											onChange={handleChangeDate}
										></RangePicker>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
					{/**
					 * Panel de control Tanque
					 */}
					<Col xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
						<Card bordered={false} className='criclebox '>
							<div className='number'>
								<Row align='middle' gutter={[24, 0]}>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Select
											onChange={handleChangeTank}
											placeholder='Choose The Tank'
											name='tank'
											size='middle'
										>
											<Option value='4d976feb5e201fa0'>Tank 1</Option>
										</Select>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
					{/**
					 * Nombre del Tanque Seleccionado
					 */}
					<Col xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
						<Card bordered={false} className='criclebox '>
							<div className='number'>
								<Row align='middle' gutter={[24, 0]}>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Title align='center' level={4}>
											{lastValues.name}
										</Title>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
					{/**
					 * Reloj
					 */}
					<Col xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
						<Card bordered={false} className='criclebox '>
							<div className='number'>
								<Row align='middle' gutter={[24, 0]}>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Text align='justify'>{dataTime}</Text>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
				<Divider />
				{/**
				 * Cards de los ultimas mediciones de las variables
				 */}
				<Row className='rowgap-vbox' gutter={[24, 0]}>
					{cart.map((c, index, array) => (
						<Col
							key={index}
							xs={24}
							sm={24}
							md={12}
							lg={6}
							xl={6}
							className='mb-24'
						>
							<Popover content={c.scale}>
								<Card bordered={false} className='criclebox '>
									<div className='number'>
										<Row align='middle' gutter={[24, 0]}>
											<Col xs={18}>
												<span>{c.etiqueta}</span>
												<Title level={3}>
													{c.value} <small>{c.unit}</small>
												</Title>
												<small className={c.bnb}>{c.time}</small>
											</Col>
											<Col xs={6}>
												<div className='icon-box'>{c.icon}</div>
											</Col>
										</Row>
									</div>
								</Card>
							</Popover>
						</Col>
					))}
				</Row>
				<Divider />
				<Charts parameters={data} />
				<Divider />
			</div>
		</>
	);
}
export default Home;
