import {
	Button,
	Row,
	Col,
	Card,
	Table,
	Typography,
	Select,
	Space,
	DatePicker,
} from 'antd';

import axios from 'axios';
import { server } from '../api/axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaEye } from 'react-icons/fa';
import moment from 'moment';
import { GenerateExcel } from '../api/Reports/ReportExcel';
import { GenerateCsv } from '../api/Reports/ReportCsv';

// Images
const { Title } = Typography;
/**
 * Columnas de la Tabla
 */
const columnas = [
	{
		title: 'DATE',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'VARIABLE',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'VALUE',
		dataIndex: 'value',
		key: 'value',
	},
	{
		title: 'LOCATION',
		dataIndex: 'location',
		key: 'location',
	},
	{
		title: 'TANK',
		dataIndex: 'tank',
		key: 'tank',
	},
	{
		title: 'SMART CONTRACT',
		dataIndex: 'smartContract',
		key: 'smartContractt',
	},
	{
		title: 'STATUS',
		dataIndex: 'status',
		key: 'status',
	},
];
// table code start
/**
 * Opciones Select
 */
const { Option } = Select;
const { RangePicker } = DatePicker;

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};
const Blockchain = () => {
	/**
	 * Definición captura de la zona horaria del cliente
	 */
	const timeZone = moment.tz.guess();
	/**
	 * Hooks Filtros (Parametros de las consultas)
	 */

	/**
	 * Control Boton Submit
	 * @param {*} event
	 */
	const handleSubmit = (event) => {
		getTest();
	};
	/**
	 * Hook control toast
	 */
	const [data, setData] = useState([]);
	// const [isLoading, setIsLoading] = useState(true);
	const getTest = async () => {
		// const id = toast.loading('Loading...');

		await axios
			.get(`${server()}/mg/block`, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				let dtBlock = [];
				dtBlock = response.data;
				dtBlock.map((element, index, self) => {
					self[index].date = moment(element.Date)
						.clone()
						.tz(timeZone)
						.format('YYYY-MM-DD HH:mm');
					self[index].status = (
						<>
							<Space.Compact block size='middle'>
								<Button type='primary' className='tag-primary'>
									<FaCheckCircle /> verified
								</Button>
								<Button
									type='primary'
									className='tag-primary'
									onClick={() => {
										window.open(
											`${self[index].block.urlCommitmentHash}`,
											'_blank'
										);
									}}
								>
									<FaEye /> View
								</Button>
							</Space.Compact>
						</>
					);
					self[index].smartContract = self[index].block.smartContract;
					return self;
				});
				setData(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	/**
	 * Hook control get data
	 */
	useEffect(() => {
		getTest();
	}, []);

	return (
		<>
			<div className='tabled'>
				<Row>
					<Col xs='24' xl={24}>
						<Card
							bordered={true}
							className='criclebox tablespace mb-24'
							title='Information Validation'
						>
							<div className='table-responsive'>
								<Table
									columns={columnas}
									dataSource={data}
									pagination={true}
									className='ant-border-space'
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default Blockchain;
