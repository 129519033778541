import axios from 'axios';
import { Card, Col, Row, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useState, useEffect } from 'react';
import { server } from '../../api/axios';
import { Column, Line, Area } from '@ant-design/plots';
import { ConfigChart } from './Config';
export default function Charts(parameters) {
	const parameter = {};
	// console.log(parameters.parameters)
	// parameter.tanque = parameters.parameters.tanque;

	const { Title, Text } = Typography;
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(parameters.parameters);
	});

	return (
		<>
			<Row gutter={[6, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-24'>
					<Card bordered={false} className='criclebox h-full'>
						<Title level={5}>Water Temperature</Title>
						<Area
							{...ConfigChart(
								data.filter((data) => data.name === 'waterTemperature')
							)}
						/>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-24'>
					<Card bordered={false} className='criclebox h-full'>
						<Title level={5}>Temperature</Title>
						<Area
							{...ConfigChart(
								data.filter((data) => data.name === 'Temperature')
							)}
						/>
					</Card>
				</Col>
			</Row>
			<Row gutter={[6, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-24'>
					<Card bordered={false} className='criclebox h-full'>
						<Title level={5}>pH</Title>
						<Area {...ConfigChart(data.filter((data) => data.name === 'ph'))} />
					</Card>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-24'>
					<Card bordered={false} className='criclebox h-full'>
						<Title level={5}>Dissolved Oxygen</Title>
						<Area
							{...ConfigChart(
								data.filter((data) => data.name === 'dissolvedOxygen')
							)}
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
}
