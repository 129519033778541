export const ConfigChart = (data) => {
	const config = {
		data,
		padding: 'auto',
		xField: 'date',
		yField: 'value',
		height: 180,
		slider: { start: 0.5, end: 1 },
		autoFit: true,
	};
	return config;
};
