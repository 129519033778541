import 'antd/dist/antd.min.css';
import './assets/styles/styles.css';
import './assets/styles/main.css';
import './assets/styles/responsive.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AuthRoutes from './routes/auth-routes.js';
import keycloakAuth from './api/service/auth.js';
import { server } from './api/axios';
import io from 'socket.io-client';

// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
	// const socket = io(`/`);

	// socket.emit('test', 'Hola desde la vista home');
	return (
		<div className='App'>
			<ReactKeycloakProvider authClient={keycloakAuth}>
				<AuthRoutes />
			</ReactKeycloakProvider>
		</div>
	);
}
export default App;
