import { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../api/axios';
import { useKeycloak } from '@react-keycloak/web';
import { ToastContainer, toast } from 'react-toastify';
import {
	Row,
	Col,
	Card,
	Button,
	Avatar,
	Switch,
	Input,
	Tooltip,
	Form,
} from 'antd';

import campana from '../assets/images/campana.png';

function Notifications() {
	const { keycloak, initialized } = useKeycloak();
	const [notificationSettings, setNotificationSettings] = useState({
		idClient: `${keycloak.tokenParsed.sub}`,
		ph: true,
		temperature: true,
		temperatureWater: true,
		disolvedOxigen: true,
		emails: 'evergara@gmail.com',
		whatsapp: true,
	});

	const [isModalVisible, setIsModalVisible] = useState(false);

	// const [showInputNumber, setShowInputNumber] = useState(false);
	// const [showInputEmail, setShowInputEmail] = useState(false);
	// const [email, setEmail] = useState('evergara@gmail.com');
	// const [imageURL, setImageURL] = useState(false);
	// const [loading, setLoading] = useState(false);

	const saveChanges = (e) => {
		e.preventDefault();
		console.log(notificationSettings);
	};
	const onFinish = (values) => {
		// values.idClient = `${keycloak.tokenParsed.sid}`;
		setNotificationSettings(values);
		const msToast = toast.loading('Loading...');
		toast.update(msToast, {
			render: 'Loading',
			type: 'info',
			closeOnClick: true,
			autoClose: true,
			isLoading: false,
		});
		axios
			.post(`${server()}/notifications/configurations`, notificationSettings, {
				headers: { 'Content-Type': 'application/json' },
				withCredentials: false,
			})
			.then((response) => {
				// console.log(response);
				toast.update(msToast, {
					position: 'top-center',
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'colored',
					render: 'Save Successfuly',
					type: 'success',
					isLoading: false,
				});
			})
			.catch((er) => {
				toast.update(msToast, {
					position: 'top-center',
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'colored',
					render: 'Ups... Changes not save',
					type: 'error',
					isLoading: false,
				});
			});

		// console.log(notificationSettings);
	};

	useEffect(() => {});

	return (
		<>
			<div className='layout-content'>
				<ToastContainer
					position='top-center'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
				<Row>
					<Col span={24} className='mb-24'>
						<Card
							className='layout-content'
							bodyStyle={{ display: 'none' }}
							title={
								<Row justify='space-between' align='middle' gutter={[24, 0]}>
									<Col span={24} md={12} className='col-info'>
										<Avatar.Group>
											<Avatar size={74} shape='square' src={campana} />
											<div className='avatar-info'>
												<h4 className='font-semibold m-0'>
													Notification Settings
												</h4>
											</div>
										</Avatar.Group>
									</Col>
								</Row>
							}
						></Card>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} className='mb-24'>
						<Form onFinish={onFinish}>
							<Row>
								<Col span={12}>
									<Card
										bordered={true}
										className='header-solid h-full'
										title={
											<h6 className='font-semibold m-0'>
												Notifications Settings
											</h6>
										}
									>
										<ul className='list settings-list'>
											<li>
												<h6 className='list-header text-sm text-muted'>
													Variables
												</h6>
											</li>
											<li>
												<Form.Item
													name='ph'
													label='Send Notifications Potential of Hydrogen (Ph)'
												>
													<Switch
													// name='ph'
													// onChange={handleChanges}
													// defaultChecked={notificacionSettings.ph}
													/>
												</Form.Item>
											</li>
											<li>
												<Form.Item
													name='waterTemperature'
													label='Water Temperature'
												>
													<Switch
													// defaultChecked={
													// 	notificacionSettings.temperatureWater
													// }
													/>
												</Form.Item>
											</li>
											<li>
												<Form.Item
													name='temperature'
													label='Enviroment temperature'
												>
													<Switch
													// defaultChecked={notificacionSettings.temperature}
													/>
												</Form.Item>
											</li>
											<li>
												<Form.Item
													name='oxigenDisolved'
													label='Dissolved Oxygen'
												>
													<Switch></Switch>
												</Form.Item>
											</li>
										</ul>
									</Card>
								</Col>

								<Col span={12}>
									<Card
										bordered={true}
										className='header-solid h-full'
										title={
											<h6 className='font-semibold m-0'>
												Communication channels
											</h6>
										}
									>
										<ul className='list settings-list'>
											<li>
												<h6 className='list-header text-sm text-muted m-0'>
													APPLICATION
												</h6>
											</li>
											<li>
												{/* <Form.Item name='isActive'>
														<Switch />
													</Form.Item> */}

												<Form.Item name='emails' label='Enter your Emails'>
													<Tooltip
														placement='bottomLeft'
														title='Enter the Emails Separated by Commas'
														color='blue'
													>
														<Input />
													</Tooltip>
												</Form.Item>
											</li>
											<li>
												<Form.Item name='phones' label='WhatsApp'>
													<Switch />
												</Form.Item>
											</li>
										</ul>
									</Card>
								</Col>
								<Col span={24} md={24} className='mb-24 '></Col>
							</Row>
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Save Changes
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default Notifications;
