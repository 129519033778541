import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { FaFileCsv } from 'react-icons/fa';
export const GenerateCsv = (dataSet) => {
	const headers = [
		{ label: 'ID', key: '_id' },
		{ label: 'TANK', key: 'tanque' },
		{ label: 'VARIABLE', key: 'name' },
		{ label: 'VALUE', key: 'value' },
		{ label: 'TIMESTART', key: 'TimeStart' },
		{ label: 'TIMESTOP', key: 'TimeStop' },
		{ label: 'DATE', key: 'Date' },
		{ label: 'LOCATION', key: 'Location' },
		{ label: 'TABLE', key: '__v' },
	];
	return (
		<>
			<CSVLink
				data={dataSet.dataSet}
				filename={dataSet.fileName}
				headers={headers}
			>
				<Button type='primary' icon={<FaFileCsv />}>
					Download CSV File
				</Button>
			</CSVLink>
		</>
	);
};
