/**
 * Definición de URL Servidores
 */
const host = 'http://localhost';
const port = '3132';
/* export default axios.create({
    baseURL: 'http//:localhost:3001/'
}); */
/**
 *
 * @returns path Server Local
 */
export function server() {
	 //const server = 'http://192.168.10.41:3132';
	 //const server = host + ':' + port;
	const server = 'https://iot-backend.idtolu.net';

	return server;
}
/**
 *
 * @returns Path MicroServer IDT
 */
export function auth() {
	const server = 'http://179.1.87.35:8087/api/v1';
	return server;
}
